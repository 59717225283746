.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  color: #333;
}

.img {
  max-width: 100%;
  height: auto;
  display: block; /* Varmistaa, että ei ole ylimääräisiä marginaaleja ympärillä */
}

.header {
  color: white;
  margin-bottom: 40px;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url("../assets/images/oma2_crop.jpg");

  background-size: cover;
  background-position: top;
  height: 100vh; /* tämä määrittelee korkeuden koko näytön korkuiseksi */
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background: rgb(67, 235, 237);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.background {
  background-image: url("oma.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh; /* tämä määrittelee korkeuden koko näytön korkuiseksi */
}

.navbar {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #5377e4; /* Taustaväriä, muokkaa tarpeen mukaan */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Hieman varjostusta */
}

.language-buttons {
  /* Jos haluat lisätilaa nappuloiden välille */
  gap: 10px;
}

button {
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
}

.navbarCustom {
  background-color: #f8f9fa;
}

.navBrandCustom {
  font-weight: bold;
  color: red;
}

.navLinkCustom {
  color: green;
  padding: 10px;
}

.navLinkCustom:hover {
  color: blue;
}

.footer {
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
}

.socialLink {
  margin: 0 10px;
  color: white;
  font-size: 1.2em; /* Säädä koon mukaan */
}

.socialLink:hover {
  color: #ddd;
}

.markdownContainer {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: space-around;
  }
}
